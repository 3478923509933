import Store from '../../store'
import Config from '../../config/'

const getPrintTitleTitle = (id) => {
  return Store.getters['printTitle/printTitleById'](id).title
}

const getImagePath = function (name, width = 608, height = '') {
  if (height !== '') {
    height = '/' + height
  }
  return Config.covers.baseUrl() + '/recent/' + name + '/' + width + height
}

export default {
  getPrintTitleTitle,
  getImagePath
}
